<template>
    <div>
        <v-radio-group v-model="mediaType" row>
            <v-radio
                v-for="option in options"
                :key="option.id"
                :label="option.label"
                :value="option.type"
            ></v-radio>
        </v-radio-group>
        <div>
            <div v-if="mediaType === `image_url`">
                <div
                    v-if="imageUrl && !isPdf"
                    class="d-flex justify-center ma-4 image-container"
                >
                    <v-img
                        contain
                        width="350"
                        height="250"
                        :src="imageUrl"
                    ></v-img>
                    <v-btn
                        class="remove-media"
                        icon
                        depressed
                        @click="removeImage()"
                    >
                        <v-icon color="error">
                            fa-solid fa-x
                        </v-icon>
                    </v-btn>
                </div>
                <v-file-input
                    v-else
                    v-model="imageUrl"
                    counter
                    outlined
                    color="primary"
                    :placeholder="$t(`builder.components.fileInput`)"
                    class="file-input input-remove-spacing"
                    :accept="acceptedFileTYpes"
                    :show-size="1000"
                    height="120"
                    required
                    :error-messages="imageErrors"
                    @input="
                        $v.imageUrl.$touch();
                        inputMedia();
                    "
                    @change="
                        $v.imageUrl.$touch();
                        inputMedia();
                    "
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip color="primary" dark label small>
                            {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </div>
        </div>
        <div v-if="mediaType === `youtube_url`">
            <v-text-field
                v-model="youtubeUrl"
                label="Video"
                class="input-remove-spacing"
                outlined
                clearable
                required
                dense
                :error-messages="videoErrors"
                @input="
                    $v.youtubeUrl.$touch();
                    inputMedia();
                "
            ></v-text-field>
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { blobToBase64 } from "@/helpers/generalHelpers.js";
export default {
    name: "SelectProjectMedia",

    validations: {
        youtubeUrl: {
            required,
        },
        imageUrl: {
            required,
        },
    },

    props: {
        project: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
        importedFile: {
            required: false,
            type: String,
            default: () => {
                return null;
            },
        },
        type: {
            required: false,
            type: Number,
            default: () => {
                return 1;
            },
        },
        file: {
            required: false,
            type: String,
            default: "",
        },
    },

    data() {
        return {
            mediaType: this.project.youtube_url ? "youtube_url" : "image_url",
            youtubeUrl: this.project.youtube_url,
            imageUrl: this.project.image_url,
            mediaOptions: [
                {
                    id: 1,
                    type: "image_url",
                    label: "image",
                },
                {
                    id: 2,
                    type: "youtube_url",
                    label: "video",
                },
            ],
            mediaOptionsPdf: [
                {
                    id: 1,
                    type: "image_url",
                    label: "PDF",
                },
                {
                    id: 2,
                    type: "youtube_url",
                    label: "video",
                },
            ],
            isPdf: false,
        };
    },

    computed: {
        imageErrors() {
            const errors = [];
            if (!this.$v.imageUrl.$dirty) return errors;
            !this.$v.imageUrl.required &&
                errors.push(this.$i18n.t(`messages.error.required`));
            return errors;
        },
        videoErrors() {
            const errors = [];
            if (!this.$v.youtubeUrl.$dirty) return errors;
            !this.$v.youtubeUrl.required &&
                errors.push(this.$i18n.t(`messages.error.required`));
            return errors;
        },
        options() {
            return this.type != 4 ? this.mediaOptions : this.mediaOptionsPdf;
        },
        acceptedFileTYpes() {
            return this.type != 4 ? "image/*" : "application/pdf";
        },
    },

    methods: {
        inputMedia() {
            let requiredField = "youtubeUrl";
            let media =
                this.mediaType == "image_url" ? this.imageUrl : this.youtubeUrl;

            if (this.mediaType == "image_url") {
                requiredField = "imageUrl";
                if (media) {
                    let fileExtension = media.name.split(".").pop();
                    this.isPdf = fileExtension == "pdf" ? true : false;
                }
            }
            if (this.mediaType == "image_url" && !this.isPdf) {
                blobToBase64(media).then((resault) => {
                    this.imageUrl = resault;
                });
            }
            this.$emit("update:file", media);
            this.$emit("update:file-type", this.mediaType);
            this.$emit("update:in-valid", this.$v[requiredField].$invalid);
        },

        removeImage() {
            this.imageUrl = null;
            this.$emit("update:file", null);
            this.$emit("update:in-valid", true);
        },
    },

    watch: {
        importedFile(newValue) {
            if (newValue) this.file = newValue;
        },
        mediaType(_newVal, oldVal) {
            this.$emit("update:file-type", this.mediaType);
            this.$emit("update:file", null);
        },
    },

    created() {
        if (this.project.library_file) {
            this.$emit("update:file", null);
            this.$emit("update:in-valid", true);
        }
        if (this.project) {
            this.mediaType = "image_url";
            this.$emit("update:file", this.project.image_url);
        }
        if (this.project.youtube_url) {
            this.mediaType = "youtube_url";
            this.$emit("update:file", this.project.youtube_url);
        }
        this.$emit("update:file-type", this.mediaType);
    },
};
</script>

<style lang="scss" scoped>
.input-remove-spacing {
    letter-spacing: 0;
}

.image-container {
    position: relative;
}

.remove-media {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
