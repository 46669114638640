<template>
    <v-text-field
        v-model="value"
        :label="label"
        class="input-remove-spacing"
        outlined
        clearable
        required
        dense
        :error-messages="valueErrors"
        @input="
            $v.value.$touch();
            emit();
        "
    ></v-text-field>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: "ProjectName",

    validations: {
        value: {
            required,
        },
    },

    props: {
        name: {
            required: false,
            type: String,
            default: () => {
                return null;
            },
        },

        label: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            value: null,
        };
    },

    computed: {
        valueErrors() {
            const errors = [];
            if (!this.$v.value.$dirty) return errors;
            !this.$v.value.required &&
                errors.push(this.$i18n.t(`messages.error.required`));
            return errors;
        },
    },

    methods: {
        emit() {
            this.$emit("update:project-name", this.value);
            this.$emit("update:in-valid", this.$v.$invalid);
        },
    },

    watch: {
        name(newValue) {
            if (newValue) this.value = newValue;
        },
    },

    created() {
        this.value = this.name;
    },
};
</script>

<style lang="scss" scoped>
.input-remove-spacing {
    letter-spacing: 0;
}
</style>
