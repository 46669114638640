<template>
    <v-row>
        <v-col cols="12">
            <h2 class="">
                {{ $t(`builder.components.addItenTitle`) }}
            </h2>
            <v-card flat width="450" class="mt-4" color="white">
                <v-text-field
                    v-model="value"
                    :placeholder="$t(`builder.components.addIten`)"
                >
                    <template v-slot:append>
                        <v-btn
                            text
                            large
                            color="primary"
                            :disabled="!value"
                            @click="addToLIst()"
                        >
                            <v-icon>fa-solid fa-plus</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
                <v-list dense shaped color="white">
                    <v-list-item
                        v-for="(item, i) in list"
                        :key="i"
                        class="bg-list-item my-1"
                    >
                        <v-list-item-content class="pa-0">
                            <v-list-item-title
                                class="requirements-list-item"
                                v-text="item"
                            ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn text dense @click="removeItem(i)">
                                <v-icon color="error lighten-1">
                                    fa-solid fa-xmark
                                </v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "AddIngredients",

    props: {
        ingredients: {
            required: false,
            type: Array,
            default: () => {
                return [];
            },
        },
    },

    data() {
        return {
            list: [],
            value: null,
        };
    },

    methods: {
        addToLIst() {
            this.list.push(this.value);
            this.$emit("update:list", this.list);
            this.value = null;
        },

        removeItem(index) {
            this.list.splice(index, 1);
            this.$emit("update:list", this.list);
        },
    },

    created() {
        this.list = this.ingredients;
    },
};
</script>

<style lang="scss" scoped>
.bg-list-item {
    background-color: var(--v-primary-lighten1);
    height: 40px;
}
.requirements-list-item {
    font-size: 1em !important;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    font-weight: 500;
    color: var(--v-text-lighten1);
    line-height: 2em;
}
</style>
